<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">EDIT BOOK</h2></div>
      <div class="box"><el-button @click="$onCommandParams('booksReport')">BACK</el-button></div>
    </div>

    <el-divider class="mv-10 o-020"></el-divider>

    <vue-scroll class="scroll-area" :settings="settings" :style="{height: scrollHeight + 'px'}">

      <div class="mt-30" style="max-width: 675px;">
        <div class="flex gaps">
          <div class="box">
            <el-date-picker
              size="small"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              v-model="form.day"
              type="date"
              :picker-options="pickerOptions"></el-date-picker>
          </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex gaps mt-10">
          <div class="box flex grow" v-for="(type, index) in types" :key="index + '-type'">
            <div class="box grow card-base clickable"
              @click="selectType(index)"
              :class="[form.ticket.idx === index ? 'bg-accent' : '']">
              <h4 class="box grow pl-5 m-10">{{type.name}}</h4>
            </div>
          </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex wrap grid-12">
            <div v-for="(s, index) in sessions" :key="index + '-session'"
              @click="selectSession(index)"
              :class="[
                {'bg-accent': form.session.idx === index },
                {'locked-warning-panel': isBlock(s) }
              ]"
              class="box card-base m-5 p-5 session-time clickable">
                <div class="flex column">
                  <h5 class="box center p-5"
                    :class="[form.session.idx === index ? '' : 'o-050']">{{s.name}}</h5>
                  <h4 class="box center">{{s.time}}</h4>
                </div>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex column gaps">
          <div class="box flex gaps">
            <div class="box left center" style="min-width: 100px;">
              <h4 class="mt-10 mb-5 o-050">NUMBER</h4>
            </div>
            <div class="box">
              <el-input-number
                size="small"
                v-model="form.ticket.persons"></el-input-number>
            </div>
          </div>

          <div class="box grow flex gaps">
            <div class="box left center" style="min-width: 100px;">
              <h4 class="mt-10 mb-5 o-050">CUSTOMER</h4>
            </div>
            <div class="box">
              <el-select
                size="small"
                v-model="form.customer.id"
                filterable
                remote
                reserve-keyword
                placeholder="3 symbols"
                @change="selectCustomer"
                :remote-method="remoteMethod">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex column gaps">
          <div class="box flex" v-for="(a, index) in form.addons" :key="index + '-addon'">
            <div class="box" style="width: 180px">
              <div>{{a.name}}</div>
            </div>
            <div class="box left flex">
              <div class="box center">
                  <el-input-number
                    size="small"
                    v-model="a.count"
                    :step="1" :min="0"></el-input-number>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-20">
          <el-button type="primary" @click="submit">Save</el-button>
        </div>
      </div>

    </vue-scroll>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'EditBook',

  data () {
    return {
      options: [],
      form: {
        addons: [],
        customer: {
          id: '',
          first: '',
          surname: ''
        },
        ticket: {
          idx: -1
        },
        session: {
          idx: -1
        },
        day: '',
        discount: {},
        amount: -1,
        createDate: new Date(),
        isRefund: false,
        paymentStatus: '',
        useStatus: '',
        _id: ''
      },
      day: new Date(),
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() + (1000 * 60 * 60 * 24) < Date.now() ||
           time.getTime() - (1000 * 60 * 60 * 24 * 30) > Date.now()
        }
      },
      settings: {
        maxScrollbarLength: 60
      },
      book: {
        customer: {
          birthday: '',
          email: '',
          first: '',
          id: '',
          phone: '',
          surname: ''
        }
      }
    }
  },

  computed: {
    ...mapState({
      types: state => state.type.types,
      members: state => state.member.members,
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'addons',
      'getBookById',
      'sessionsByDay',
      'customersForSelect',
      'getMemberById'
    ]),
    current () {
      return this.getBookById[this.$route.params.id]
    },
    sessions () {
      return this.sessionsByDay[this.current.day]
    },
    clearOption () {
      return [{
        value: this.current.customer.id,
        label: this.current.customer.first + ' ' + this.current.customer.surname
      }]
    },
    scrollHeight () {
      return innerHeight - 160
    }
  },

  methods: {
    ...mapActions(['editBook']),
    remoteMethod (query) {
      if (query !== '' && query.length > 2) {
        this.options = this.customersForSelect.filter(item => {
          return item.label.toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        })
      } else {
        this.options = this.clearOption
      }
    },
    selectType (index) {
      this.form.ticket.idx = index
      this.form.ticket.name = this.types[index].name
      this.form.ticket.price = this.types[index].price
    },
    selectSession (index) {
      if (!this.isBlock(this.sessions[index])) {
        this.form.session = {
          idx: index,
          name: this.sessions[index].name,
          time: this.sessions[index].time
        }
      }
    },
    selectCustomer (id) {
      let member = this.getMemberById[id]
      if (member) {
        this.form.customer = {
          birthday: member.birthday,
          email: member.email,
          first: member.first,
          id: member._id,
          phone: member.phone,
          surname: member.surname
        }
      }
    },
    isBlock (item) {
      return item.hasOwnProperty('block') &&
        item.block.hasOwnProperty('isBlock') &&
        item.block.isBlock
    },
    submit () {
      this.editBook({
        id: this.$route.params.id,
        content: this.form
      })
        .then(() => {
          this.$message({
            message: 'book edited successfully',
            type: 'success'
          })
        })
    }
  },

  mounted () {
    let addons = this.addons.map(a => {
      let aa = this.current.addons.find(aaa => aaa._id === a._id)
      return aa
        ? Object.assign({}, aa)
        : Object.assign({}, a, { count: 0 })
    })
    let customer = Object.assign({}, this.current.customer)
    let ticket = Object.assign({}, this.current.ticket)
    let session = Object.assign({}, this.current.session)
    let discount = Object.assign({}, this.current.discount)
    let day = new Date(new Date(this.current.day).toString().split('GMT')[0] + ' UTC')
      .toISOString().slice(0, 10)
    this.form = Object.assign({}, {
      addons,
      customer,
      ticket,
      session,
      day,
      discount,
      amount: this.current.amount,
      createDate: this.current.createDate,
      isRefund: this.current.isRefund,
      paymentStatus: this.current.paymentStatus,
      useStatus: this.current.useStatus,
      _id: this.current._id
    })
    this.options = this.clearOption
  }
}
</script>
